<template>
  <div :class="{delay: venues && !venues.length, hide: true}">
    <div class="container with-footer mt-5">
      <div class="row">
        <div class="col-sm">
          <b-card class="card" header="Názov prevádzky">
            <b-form-group v-for="(field, i) in companyFields" :label="field.title" :label-for="field.name" :key="i">
              <b-form-input v-model="$v.company[field.name].$model" :state="validateState(field.name)" />
              <b-form-invalid-feedback>{{field.warning}}</b-form-invalid-feedback>
            </b-form-group>

            <b-button @click="onSubmit">Vytvoriť</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .card {
    max-width: 700px;
    margin: 0 auto;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  }

  .hide {
    opacity: 0;
  }
  .delay {
    opacity: 1;
    transition-delay: 500ms;
    transition-duration: 0ms;
  }
</style>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { required, minLength } from "vuelidate/lib/validators"

  export default {
    components: {},
    data() {
      return {
        company: {
          name: '',
        },
        companyFields: [
          { name: 'name', title: 'Pre nastavenie Fudoma aplikácie pre vašu reštauráciu zadajte názov vašej prevádzky a kliknite na tlačidlo Vytvoriť.', warning: 'Toto pole je povinné a musí obsahovať aspoň 3 znaky.'},
        ]
      }
    },
    validations: {
      company: {
        name: {required, minLength: minLength(3)},
      }
    },
    mounted() {
      if(!this.isLoggedIn)
        this.$router.push('/login')
    },
    watch: {
      venues() {
        // console.log(this.venues.length)
        if(this.venues.length) {
          this.$router.push('/app/daily')
        }
      }
    },
    computed: {
      ...mapGetters('wAuth', ['user', 'isLoggedIn']),
      ...mapGetters('venues', ['venues'])
    },
    methods: {
      ...mapActions('wAuth', ['updateUserCompanyInfo', 'logout']),
      ...mapActions('venues', ['updateVenueInfo']),

      async onSubmit() {
        this.$v.company.$touch()
        if (this.$v.company.$anyError)
          return

        this.$v.company.$reset()
        this.$wToast.clear_loading()

        if(!this.user.company) {
          await this.updateUserCompanyInfo({
            name: "",
            ico: "",
            dic: "",
            street: "",
            city: "",
            zip: "",
            country: ""
          }).catch(e => this.$wToast.error(e))
        }

        this.updateVenueInfo({venue: this.company}).then(_ => {
          this.$wToast.success('Prevádzka bola vytvorená')
        }).catch(e => this.$wToast.error(e))
      },

      validateState(field) {
        const { $dirty, $error } = this.$v.company[field]
        return $dirty ? !$error : null
      },

      onLogout: async function () {
        await this.logout()
        this.$wToast.success('Boli ste úspešne odhlásení')
        await this.$router.push('/')
      }
    }
  }
</script>
